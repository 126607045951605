
import {Options, Vue} from "vue-class-component";
import PaymentSettings from "@/views/PaymentSettings.vue";
import NotificationSettings from "@/views/NotificationSettings.vue";
import { user, globalSettings } from "@/store";

@Options({
  components: {
    PaymentSettings,
    NotificationSettings
  }
})

export default class Profile extends Vue {

  tableData = [
    {
      key: "UserID",
      value: user.value.subject
    },
    {
      key: "Name",
      value: user.value.name
    },
    {
      key: "Nutzername",
      value: user.value.preferredName
    },
    {
      key: "Email",
      value: user.value.email
    }
  ]
  
  getCreditEnabled(): boolean {
    return globalSettings.value.creditEnabled
  }

}
