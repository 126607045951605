
import authService from "@/services/AuthService";
import RESTService from "@/services/RESTService";
import {Transaction} from "@/model/Transaction";
import TransactionContainer from "@/components/TransactionContainer.vue";
import {Options, Vue} from "vue-class-component";
import { ref } from 'vue';
import { Item } from "@/model/Item";

@Options({
  components: {
    TransactionContainer
  }
})

export default class History extends Vue {
  authService = authService
  transactions: Transaction[] = []
  drawer = false
  unpaid = false
  items: Item[] = []

  async mounted() {
    this.transactions = await RESTService.getTransactions()
  }

  shortcuts = [
    {
      text: 'Letzte Woche',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        return [start, end]
      },
    },
    {
      text: 'Letzter Monat',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        return [start, end]
      },
    },
    {
      text: 'Letzten 3 Monate',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        return [start, end]
      },
    },
  ]

  value1 = '';

  async onChangePaid() {
    if(this.unpaid) {
      this.transactions = await RESTService.getUnpaidTransactions()
    } else {
      this.transactions = await RESTService.getTransactions()
    }
  }

  async onTimeChanged() {

    if(this.value1 == null) {
      this.transactions = await RESTService.getTransactions()
    } else {
      this.transactions = await RESTService.getTransactionsinTimeRange(new Date(this.value1[0]).toISOString(), new Date(this.value1[1]).toISOString())
    }

  }

}

