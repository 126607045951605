import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b0c815c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_ShopItem = _resolveComponent("ShopItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      type: "card",
      modelValue: _ctx.selectedCategory,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCategory) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          name: "COLDDRINKS",
          label: "Kaltgetränke"
        }),
        _createVNode(_component_el_tab_pane, {
          name: "HOTDRINKS",
          label: "Heißgetränke"
        }),
        _createVNode(_component_el_tab_pane, {
          name: "FOOD",
          label: "Essen"
        }),
        _createVNode(_component_el_tab_pane, {
          name: "SNACKS",
          label: "Snacks"
        }),
        _createVNode(_component_el_tab_pane, {
          name: "VOUCHERS",
          label: "Gutscheine/Tickets"
        }),
        _createVNode(_component_el_tab_pane, {
          name: "MISC",
          label: "Sonstiges"
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemContainers, (itemContainer) => {
        return (_openBlock(), _createElementBlock("div", {
          key: itemContainer.item.id
        }, [
          (_ctx.selectedCategory == itemContainer.item.category)
            ? (_openBlock(), _createBlock(_component_ShopItem, {
                key: 0,
                editable: true,
                "item-container": itemContainer,
                onItemChanged: _ctx.reloadItems
              }, null, 8, ["item-container", "onItemChanged"]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _createVNode(_component_ShopItem, {
        creatable: true,
        "item-container": _ctx.emptyItemContainer,
        onItemChanged: _ctx.reloadItems
      }, null, 8, ["item-container", "onItemChanged"])
    ])
  ], 64))
}