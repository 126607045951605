import {
    AuthorizationNotifier, AuthorizationRequest, AuthorizationRequestHandler,
    AuthorizationServiceConfiguration, BaseTokenRequestHandler, BasicQueryStringUtils, DefaultCrypto,
    FetchRequestor, GRANT_TYPE_AUTHORIZATION_CODE, LocalStorageBackend, RedirectRequestHandler, TokenRequest,
    TokenRequestHandler, TokenResponse, LocationLike, GRANT_TYPE_REFRESH_TOKEN
} from "@openid/appauth";
import jwt_decode from "jwt-decode";
import config from '@/config/config.json'

import { userLoggedIn, user, userRoles} from "@/store"
import lifecycleService from "@/services/LifecycleService";
import {JWT} from "@/model/jwt";
class NoHashQueryStringUtils extends BasicQueryStringUtils {
    parse(input: LocationLike, useHash: boolean) {
        return super.parse(input, false);
    }
}
class AuthService {
    readonly requestor = new FetchRequestor();
    readonly clientId = "webapp"
    readonly issuerURL = config.issuerURL
    private readonly redirectUrl = window.location.origin + "/about"

    private readonly notifier: AuthorizationNotifier
    private tokenHandler: TokenRequestHandler
    private authorizationHandler: AuthorizationRequestHandler
    // state
    private configuration: AuthorizationServiceConfiguration | undefined

    private refreshToken?: string
    private accessToken?: string
    private accessTokenExpiryDate: Date


    constructor() {
        console.log("AuthService constructed")
        this.accessTokenExpiryDate = new Date(0);
        this.notifier = new AuthorizationNotifier();
        this.tokenHandler = new BaseTokenRequestHandler(this.requestor);
        this.authorizationHandler = new RedirectRequestHandler(
            new LocalStorageBackend(),
            new NoHashQueryStringUtils(),
            window.location,
            new DefaultCrypto()
        )
        this.authorizationHandler.setAuthorizationNotifier(this.notifier);
        this.load()
    }

    public async acquireValidAccessToken(): Promise<string> {
        if(new Date() > this.accessTokenExpiryDate) {
            await this.acquireTokenFromRefreshToken()
        }
        return this.accessToken!
    }

    private load() {
        const refreshToken = localStorage.getItem("refreshToken")
        if(refreshToken != null) {
            this.refreshToken = refreshToken!
            this.acquireTokenFromRefreshToken().then()
        }
    }

    private save() {
        if(this.refreshToken != null) {
            localStorage.setItem("refreshToken", this.refreshToken!)
        } else {
            localStorage.removeItem("refreshToken")
        }
    }

    public logout() {
        this.accessTokenExpiryDate = new Date()
        this.refreshToken = undefined
        this.accessToken = undefined
        this.save()
        userLoggedIn.value = false
        userRoles.value = []
    }

    public login(provider?: string) {
        this.fetchServiceConfiguration()
            .then(() => {
               console.log('Completed fetching configuration');
                const authRequest = new AuthorizationRequest({
                    client_id: this.clientId,
                    redirect_uri: this.redirectUrl,
                    scope: 'openid email profile offline_access',
                    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE
                });
                if(provider!= null) {
                    authRequest.extras = {"kc_idp_hint": provider}
                }
                this.authorizationHandler.performAuthorizationRequest(this.configuration!, authRequest)
            })
            .catch(error => {
                console.log("something went wrong")
            });
    }

   private fetchServiceConfiguration(): Promise<void> {
       console.log("Fetch called")
        return AuthorizationServiceConfiguration.fetchFromIssuer(
            this.issuerURL,
            this.requestor
        ).then(response => {
            console.log("Fetched service configuration", response);
            this.configuration = response;
        });
    }

    private handleAccessTokenResponse(tokenResponse: TokenResponse) {
        this.accessToken = tokenResponse.accessToken
        this.refreshToken = tokenResponse.refreshToken
        this.accessTokenExpiryDate = new Date(new Date().getTime() - 30*1000 + tokenResponse.expiresIn! * 1000)
        this.save()

        const jwt = (jwt_decode(this.accessToken) as JWT)
        userRoles.value = jwt.realm_access.roles
        if(userLoggedIn.value != true) {
            userLoggedIn.value = true
            lifecycleService.onUserLoggedIn()
        }
        user.value.name = jwt.name
        user.value.subject = jwt.sub
        user.value.email = jwt.email
        user.value.preferredName = jwt.preferred_username
    }

    public shouldForceLogin(): boolean {
        return this.refreshToken == null  && new URL(window.location.href).searchParams.get("state")  == null
    }
    public async completeAuthorizationIfPossible() {
        console.log(window.location)
        this.notifier.setAuthorizationListener((request, response, error) => {
            console.log('Authorization request complete ', request, response, error);
            if (response) {
                console.log(`Authorization Code  ${response.code}`);

                // A. First, you need to create a token request object
               const  tokenRequest = new TokenRequest({
                    client_id: this.clientId,
                    redirect_uri: this.redirectUrl,
                    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                    code: response.code,
                });
                if (request && request.internal) {
                    tokenRequest.extras = {code_verifier: request.internal.code_verifier};
                }
                // B. Again get configuration information
               this.fetchServiceConfiguration()
                    .then((oResponse) => {
                        return this.tokenHandler.performTokenRequest(this.configuration!, tokenRequest);
                    })
                    .then((oResponse) => {
                        this.handleAccessTokenResponse(oResponse)

                    })
                    .catch(oError => {
                        console.error(oError)
                    });
            }
        });
        this.authorizationHandler.completeAuthorizationRequestIfPossible().then(r => console.log(r))
    }

    private acquireTokenFromRefreshToken(): Promise<void> {
       return this.fetchServiceConfiguration().then(() => {
            const tokenRequest = new TokenRequest({
                client_id: this.clientId,
                redirect_uri: this.redirectUrl,
                grant_type: GRANT_TYPE_REFRESH_TOKEN,
                code: undefined,
                refresh_token: this.refreshToken,
                extras: undefined
            });
           return this.tokenHandler.performTokenRequest(this.configuration!, tokenRequest)
        }).then((oResponse) => {
           this.handleAccessTokenResponse(oResponse)
       })
           .catch(oError => {
               userLoggedIn.value = false
               console.error(oError)
           });
    }


}

export default new AuthService()
