import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bd89a5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "text item" }
const _hoisted_3 = { class: "text item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "box-card" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.item.name), 1),
        (_ctx.creatable || _ctx.editable)
          ? (_openBlock(), _createBlock(_component_el_popover, {
              key: 0,
              placement: "bottom",
              visible: _ctx.popoverVisible,
              "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.popoverVisible) = $event)),
              width: 400,
              trigger: "manual",
              onHide: _ctx.reloadItem
            }, {
              reference: _withCtx(() => [
                _createVNode(_component_el_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.popoverVisible = !_ctx.popoverVisible))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.editable ? "Ändern" : "Erstellen"), 1)
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  ref: "form",
                  model: _ctx.item,
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "Artikelname",
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.item.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.name) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, { label: "Kategorie" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.selectedCategory,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                          placeholder: "please select your zone"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              label: "Kaltgetränke",
                              value: "COLDDRINKS"
                            }),
                            _createVNode(_component_el_option, {
                              label: "Heißgetränke",
                              value: "HOTDRINKS"
                            }),
                            _createVNode(_component_el_option, {
                              label: "Essen",
                              value: "FOOD"
                            }),
                            _createVNode(_component_el_option, {
                              label: "Snacks",
                              value: "SNACKS"
                            }),
                            _createVNode(_component_el_option, {
                              label: "Gutscheine/Tickets",
                              value: "VOUCHERS"
                            }),
                            _createVNode(_component_el_option, {
                              label: "Sonstiges",
                              value: "MISC"
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "Standardpreis",
                      prop: "defaultPrice"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.item.defaultPrice,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.defaultPrice) = $event)),
                          type: "number",
                          step: "any"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "Steuersatz",
                      prop: "vatRate"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          prop: "vatRate",
                          modelValue: _ctx.item.vatRate,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.vatRate) = $event)),
                          type: "number",
                          step: "any"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "text",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.popoverVisible = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Schließen")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: _ctx.saveItem
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Speichern")
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        (_ctx.editable)
                          ? (_openBlock(), _createBlock(_component_el_popconfirm, {
                              key: 0,
                              "confirm-button-text": "Löschen",
                              "cancel-button-text": "Abbrechen",
                              "icon-color": "red",
                              title: "Möchtest du diesen Artikel wirklich löschen?",
                              onConfirm: _ctx.deleteItem
                            }, {
                              reference: _withCtx(() => [
                                _createVNode(_component_el_button, { type: "danger" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Löschen")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["onConfirm"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            }, 8, ["visible", "onHide"]))
          : _createCommentVNode("", true),
        (_ctx.buyable)
          ? (_openBlock(), _createBlock(_component_el_input_number, {
              key: 1,
              modelValue: _ctx.itemContainer.quantity,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.itemContainer.quantity) = $event)),
              min: 0,
              max: 10,
              onChange: _ctx.handleChange
            }, null, 8, ["modelValue", "onChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, "Preis: " + _toDisplayString(parseFloat(_ctx.item.defaultPrice).toFixed(2)) + "€", 1),
      _createElementVNode("div", _hoisted_3, "Mwst: " + _toDisplayString(parseFloat(_ctx.item.vatRate).toFixed(2)) + "%", 1)
    ]),
    _: 1
  }))
}