export class User {
    constructor(public subject: string,
                public name?: string,
                public preferredName?: string,
                public email?: string
    ) {}
}

export class ListUser {
    constructor(public id: string,
                public firstName?: string,
                public lastName?: string,
    ) {}
}
