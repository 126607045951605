import {Item} from "@/model/Item";
import {User} from "@/model/User";

export class Transaction {
    constructor(public id: string = "",
                public createdDate: string = "",
                public items: Item[] = [],
                public paymentType = "memberAccount",
                public total: number = 0,
                public state: string = "TransactionState.PENDING",
                public customer?: User,
                public cashier?: User
    ) {}

}
