export enum ItemCategory {
    FOOD = "FOOD",
    COLDDRINKS = "COLDDRINKS",
    HOTDRINKS = "HOTDRINKS",
    SNACKS = "SNACKS",
    VOUCHERS = "VOUCHERS",
    MISC = "MISC"
}

export class ItemContainer {
    constructor(public item: Item, public quantity = 0) {}
}

export class Item {
    constructor(public id: string = "", public category: ItemCategory = ItemCategory.MISC,
                public name: string = "Neuer Artikel",
                public defaultPrice: number = 0, public vatRate: number = 0) {}
}
