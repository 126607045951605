
import authService from "@/services/AuthService";
import RESTService from "@/services/RESTService";
import {Transaction} from "@/model/Transaction";
import TransactionContainer from "@/components/TransactionContainer.vue";
import {Options, Vue} from "vue-class-component";
import {Item} from "@/model/Item";
import {User, ListUser} from "@/model/User";
import {Plus, Minus, Hide} from "@element-plus/icons-vue";
import {MemberAccountCharge} from "@/model/MemberAccountCharge"
import {ElMessage} from "element-plus";

@Options({
  components: {
    Plus,
    Minus,
    Hide
  }
})

export default class PaymentAdministration extends Vue {
  authService = authService
  isChargeCreditDialogOpened = false
  users: ListUser[] = []
  selectedUser?: ListUser
  addBalance = false
  selectedChargeValue = 1.0
  shownBalance: string[] = []
  shownUserBalances: Map<string, number> = new Map<string, number>()

  async mounted() {
    this.users = await RESTService.getUsers()
  }

  async chargeCredit() {
    this.isChargeCreditDialogOpened = false
    if (!this.addBalance) this.selectedChargeValue = this.selectedChargeValue * -1
    let accountCharge: MemberAccountCharge = {
      id: "",
      createdDate: "",
      member: this.selectedUser!,
      paymentType: "cash",
      value: this.selectedChargeValue,
      state: "TransactionState.PENDING"
    }
    if (this.selectedUser) {
      console.log(this.selectedUser)
      await RESTService.createMemberAccountCharge(accountCharge, this.selectedUser.id)
      if (this.addBalance) ElMessage.success('Aufladung erfolgreich!'); else ElMessage.success('Abzug erfolgreich!')
      await this.showAndRefreshCredit(this.selectedUser.id)
    } else {
      ElMessage.error('Es ist ein Fehler aufgetreten!')
    }
  }

  async showAndRefreshCredit(sub: string) {
    try {
      if (!this.shownBalance.includes(sub)) {
        this.shownBalance.push(sub)
      }
    } catch (e) {
      ElMessage.error('Es ist ein Fehler aufgetreten!')
      console.log(e)
      return;
    }
    try {
      this.shownUserBalances.set(sub, await RESTService.getCreditFromUser(sub))
    } catch (e) {
      console.error(e)
      this.shownUserBalances.set(sub, 0)
    }
  }

  openChargeDialog(user: ListUser, addBalance: boolean) {
    this.isChargeCreditDialogOpened = true
    this.selectedUser = user
    this.addBalance = addBalance
  }

}

