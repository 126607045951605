
import authService from "@/services/AuthService";
import RESTService from "@/services/RESTService";
import {Transaction} from "@/model/Transaction";
import TransactionContainer from "@/components/TransactionContainer.vue";
import {Options, Vue} from "vue-class-component";
import { Item } from "@/model/Item";
import {ListUser, User} from "@/model/User";

@Options({
  components: {
    TransactionContainer
  }
})

export default class TransactionAdministration extends Vue {
  authService = authService
  transactions: Transaction[] = []
  drawer = false
  unpaid = false
  items: Item[] = []
  users: ListUser[] = []

  stateOptions = [
    {
      value: 'PENDING',
      label: 'Anstehend',
    },
    {
      value: 'SUCCEEDED',
      label: 'Erfolgreich',
    },
    {
      value: 'FAILED',
      label: 'Fehlgeschlagen',
    },
    {
      value: 'REFUNDED',
      label: 'Rückerstattet',
    },
  ]

  async mounted() {
    this.transactions = await RESTService.getAllTransactions()
    this.users = await RESTService.getUsers()
  }

  shortcuts = [
    {
      text: 'Letzte Woche',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        return [start, end]
      },
    },
    {
      text: 'Letzter Monat',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        return [start, end]
      },
    },
    {
      text: 'Letzten 3 Monate',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        return [start, end]
      },
    },
  ]

  date = ''
  customerValue = ''
  cashierValue = ''
  state = ''

  async reload() {
    this.transactions = await RESTService.getAllTransactions(
        this.customerValue,
        this.cashierValue,
        this.state,
        this.date ? new Date(this.date[0]).toISOString() : "",
        this.date ? new Date(this.date[1]).toISOString() : ""
    )
  }

}

