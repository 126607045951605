
import AuthService from "@/services/AuthService";
import {Options, Vue} from "vue-class-component";
import {userLoggedIn, user, userRoles} from "@/store";
import { ref, watch} from "vue";
import {globalSettings} from "@/store";
import lifecycleService from "@/services/LifecycleService";

@Options({
  components: {},
})
export default class App extends Vue {
  loggedIn = userLoggedIn
  roles = userRoles
  authService = AuthService
  user = user

  mounted() {
    lifecycleService.onApplicationLoaded()

    watch(userLoggedIn, (newState, oldState) => {
      console.log("WATCH" + newState)
      if (oldState == newState)
        return
      if (newState == true && this.$route.name == "about") {
        this.$router.push("/about")
      } else if (newState == false && this.$route.name != "about") {
        this.$router.push("/about")
      }
    })

    watch(user, (newState, oldState) => {
      console.log("WATCH", newState)
      if (oldState == newState)
        return
      this.user = user
    })

  }

  getCreditEnabled(): boolean {
    return globalSettings.value.creditEnabled
  }

}
