import axios, {AxiosRequestConfig} from "axios";
import AuthService from "@/services/AuthService";
import {Transaction} from "@/model/Transaction";
import {Item} from "@/model/Item";
import config from '@/config/config.json'
import {UserNotificationSettings} from "@/model/UserNotificationSettings";
import {User, ListUser} from "@/model/User";
import { MemberAccountCharge } from "@/model/MemberAccountCharge";
import { user } from "@/store";
import { GlobalSettings } from "@/model/GlobalSettings";

class RESTService {

    private readonly client = axios.create({
        baseURL: config.baseURL,
        timeout: 5000
    });


    constructor() {
        console.log(process.env.NODE_ENV)
        this.initInterceptor()
    }

    async getItems(): Promise<Item[]> {
        const response = await this.client.get<Item[]>('/items')
        return response.data
    }

    async getItem(id: string): Promise<Item> {
        const response = await this.client.get<Item>('/items/' + id)
        return response.data
    }

    async createItem(item: Item) {
        const response = await this.client.post('/items', item)
    }

    async deleteItem(itemId: string) {
        const response = await this.client.delete('/items/' + itemId)
    }

    async updateItem(item: Item) {
        const response = await this.client.put('/items/' + item.id, item)
    }

    async getTransactions(): Promise<Transaction[]> {
        const response = await this.client.get<Transaction[]>('/transactions/')
        return response.data
    }

    async getAllTransactions(customer?: string, cashier?: string, state?: string, startDate?: string, endDate?: string): Promise<Transaction[]> {
        let addition = ""
        if (customer || cashier || state || (startDate && endDate)) addition += "?"
        if (customer) addition += "&customer=" + customer
        if (cashier) addition += "&cashier=" + cashier
        if (state) addition += "&state=" + state
        if (startDate && endDate) addition += "&startDate=" + startDate + "&endDate=" + endDate
        const response = await this.client.get<Transaction[]>('/transactions/all' + addition)
        return response.data
    }

    async getUnpaidTransactions(): Promise<Transaction[]> {
        const response = await this.client.get<Transaction[]>('/transactions/unpaid')
        return response.data
    }

    async getTransactionsinTimeRange(start: string, end: string): Promise<Transaction[]> {
        const response = await this.client.get<Transaction[]>('/transactions?startDate=' + start + '&endDate=' + end)
        return response.data
    }

    async purchaseTransaction(transaction: Transaction) {
        return await this.client.post<Transaction>('/transactions', transaction)
    }

    async getNotificationSettings(): Promise<UserNotificationSettings> {
        const response = await this.client.get<UserNotificationSettings>('/settings/notifications')
        return response.data
    }

    async putNotificationSettings(settings: UserNotificationSettings) {
        return await this.client.put<UserNotificationSettings>('/settings/notifications', settings)
    }

    async getUsers(): Promise<ListUser[]> {
        const response = await this.client.get<ListUser[]>('/users')
        return response.data
    }

    async refundTransaction(transactionId: string) {
        return await this.client.get('/transactions/' + transactionId + '/refund')
    }

    async getCreditFromUser(userSubject: string): Promise<number> {
        const response = await this.client.get('/users/' + userSubject + '/credit')
        return response.data
    }

    async createMemberAccountCharge(memberAccountCharge: MemberAccountCharge, userId: string) {
        const response = await this.client.post('/users/' + userId + '/charges', memberAccountCharge)
    }

    async getGlobalSettings(): Promise<GlobalSettings> {
        const response = await this.client.get('/settings/global')
        return response.data
    }

    async getDogPicture(): Promise<string> {
        const response = await axios.get<DogPicture>('https://dog.ceo/api/breeds/image/random')
        return response.data.message
    }

    private initInterceptor() {
        this.client.interceptors.request.use((config: AxiosRequestConfig) => {
            return AuthService.acquireValidAccessToken().then((token) => {
                if (config?.headers != null)
                    config.headers.Authorization = "Bearer " + token
                return config
            })
        }, (error) => {
            console.log(error)
        })
    }


}

interface DogPicture {
    message: string
    status: string
}

export default new RESTService()
