import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.chargesSorted,
    style: {"width":"100%"},
    "row-class-name": _ctx.tableRowClassName
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "createdDate",
        label: "Datum"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.createdDate.replace(/T/, ' ').replace(/\..+/, '')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "value",
        label: "Datum"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.value) + "€ ", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "paymentType",
        label: "Dienstleister"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.paymentType), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data", "row-class-name"]))
}