import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07950af5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-footer" }
const _hoisted_2 = { class: "order" }
const _hoisted_3 = { class: "item-box" }
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_ShopItem = _resolveComponent("ShopItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "Einkauf bestätigen",
      width: "60%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Abbrechen")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "success",
            onClick: _ctx.handleBuy
          }, {
            default: _withCtx(() => [
              _createTextVNode("Kaufen")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_el_table, {
            data: _ctx.transactionItems,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: "Produkt"
              }),
              _createVNode(_component_el_table_column, {
                prop: "defaultPrice",
                label: "Preis"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.defaultPrice.toString().replace('.',',')) + " € ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "vatRate",
                label: "MwSt."
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.vatRate.toString().replace('.',',')) + " % ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"]),
          _createElementVNode("h2", null, "Insgesamt: " + _toDisplayString(_ctx.transactionItems.reduce((sum, item) => {return sum + item.defaultPrice}, 0).toFixed(2)), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        type: "card",
        modelValue: _ctx.selectedCategory,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCategory) = $event)),
        onTabChange: _ctx.onChangedTab
      }, {
        default: _withCtx(() => [
          (_ctx.itemCategories.includes('COLDDRINKS'))
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 0,
                name: "COLDDRINKS",
                label: "Kaltgetränke"
              }))
            : _createCommentVNode("", true),
          (_ctx.itemCategories.includes('HOTDRINKS'))
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 1,
                name: "HOTDRINKS",
                label: "Heißgetränke"
              }))
            : _createCommentVNode("", true),
          (_ctx.itemCategories.includes('FOOD'))
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 2,
                name: "FOOD",
                label: "Essen"
              }))
            : _createCommentVNode("", true),
          (_ctx.itemCategories.includes('SNACKS'))
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 3,
                name: "SNACKS",
                label: "Snacks"
              }))
            : _createCommentVNode("", true),
          (_ctx.itemCategories.includes('VOUCHERS'))
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 4,
                name: "VOUCHERS",
                label: "Gutscheine/Tickets"
              }))
            : _createCommentVNode("", true),
          (_ctx.itemCategories.includes('MISC'))
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 5,
                name: "MISC",
                label: "Sonstiges"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "onTabChange"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemContainers, (itemContainer) => {
          return (_openBlock(), _createElementBlock("div", {
            key: itemContainer.item.id
          }, [
            (_ctx.selectedCategory == itemContainer.item.category)
              ? (_openBlock(), _createBlock(_component_ShopItem, {
                  key: 0,
                  buyable: true,
                  "item-container": itemContainer,
                  onClick: _ctx.updateCartQuantity
                }, null, 8, ["item-container", "onClick"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h1", null, "Warenkorb (" + _toDisplayString(_ctx.cartQuantity) + ")", 1),
        (this.cartQuantity < 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_el_button, {
                type: "primary",
                disabled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Kaufen")
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (this.cartQuantity > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.openPurchaseDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Kaufen")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}