
import {Options, Vue} from "vue-class-component";
import RESTService from "@/services/RESTService";

@Options({})
export default class StatusPage extends Vue {
  private success = false;
  private insufficient_credits = false;
  private dogpic = "";

  mounted() {
    this.success = this.$route.params.success === "success"
    this.insufficient_credits = this.$route.params.success === "insufficientcredits"
    if (this.success)
      this.getDogPicture()
  }

  async getDogPicture() {
    this.dogpic = await RESTService.getDogPicture()
  }

  goBack() {
    this.$router.back();
  }

}
