import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c25dc800"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status-page" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_result = _resolveComponent("el-result")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.success)
      ? (_openBlock(), _createBlock(_component_el_result, {
          key: 0,
          icon: "success",
          title: "Kauf erfolgreich",
          "sub-title": "Dein Kauf wurde erfolgreich abgeschlossen!"
        }, {
          extra: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.goBack
            }, {
              default: _withCtx(() => [
                _createTextVNode("Zurück")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : (_ctx.insufficient_credits)
        ? (_openBlock(), _createBlock(_component_el_result, {
            key: 1,
            icon: "error",
            title: "Kein Guthaben",
            "sub-title": "Dein Guthaben reicht für diesen Kauf leider nicht aus!"
          }, {
            extra: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.goBack
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Zurück")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_result, {
            key: 2,
            icon: "error",
            title: "Kauf fehlgeschlagen",
            "sub-title": "Der Kauf konnte nicht abgeschlossen werden!"
          }, {
            extra: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.goBack
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Zurück")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })),
    (_ctx.success)
      ? (_openBlock(), _createElementBlock("img", {
          key: 3,
          src: _ctx.dogpic,
          alt: ":(",
          style: {"height":"20%"}
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}