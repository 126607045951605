
import RESTService from "@/services/RESTService";
import {Item, ItemCategory, ItemContainer} from "@/model/Item";
import {Options, Vue} from "vue-class-component";
import ShopItem from "@/components/ShopItem.vue";
import {ElLoading, ElMessage} from 'element-plus'
import {Transaction} from "@/model/Transaction";
import router from "@/router";

@Options({
  components: {ShopItem}
})
export default class Order extends Vue {
  private itemContainers: ItemContainer[] = [];
  private selectedCategory: ItemCategory = ItemCategory.COLDDRINKS;
  public dialogVisible = false;

  categoryFromString(category: string): ItemCategory {
    return ItemCategory[category as keyof typeof ItemCategory]
  }

  get transactionItems(): Item[] {
    return this.itemContainers.reduce((sum, c) => {
      let temp = sum
      for(let i = 0; i < c.quantity; i++) {
          temp.push(c.item)
      }
      return temp
    },[] as Item[])
  }
  get items(): Item[] {
    return this.itemContainers.map(c => c.item)
  }
  get cartQuantity() {
    return this.itemContainers.reduce((sum, c) => {return sum + c.quantity},0)
  }

  get itemCategories(): string[] {
    return this.itemContainers.filter((value, index, self) => self.indexOf(value) === index).map(i => i.item.category.toString())
  }

  mounted() {
    this.selectedCategory = this.categoryFromString(typeof this.$route.params.category === "string" ? this.$route.params.category.toUpperCase() : this.$route.params.category[0].toUpperCase())
    this.reloadItems()
  }

  async reloadItems() {
    this.itemContainers = (await RESTService.getItems()).map(i => new ItemContainer(i))
    console.log(this.itemCategories)
  }


  openPurchaseDialog() {
    if(this.cartQuantity > 0) {
      this.dialogVisible = true;
    }
  }

  clearCart() {
    this.itemContainers.forEach(c => c.quantity = 0)

  }

  onChangedTab(name: string) {
    router.push({path: "/order/category/" + name.toLowerCase()})
  }

  async handleBuy() {

    const transaction = new Transaction()
    transaction.items = this.transactionItems
    const loading = ElLoading.service({
      lock: true,
      text: 'Kauf wird durchgeführt...',
      background: 'rgba(0, 0, 0, 0.7)'
    })


    this.dialogVisible = false;
    RESTService.purchaseTransaction(transaction).then((response) => {
      loading.close()
      this.$router.push({path: "/status/success"})
    }).catch(error => {
      loading.close()
      if(error.response.status === 402) {
        this.$router.push({path: "/status/insufficientcredits"})
      } else {
        this.$router.push({path: "/status/failure"})
      }
    });
    this.clearCart()
  }

}

