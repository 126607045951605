
import { Vue, Options } from 'vue-class-component';
import {globalSettings, user, userLoggedIn} from "../store";
import PaymentSettings from "@/views/PaymentSettings.vue";
import AuthService from "@/services/AuthService";

@Options({
  components: {PaymentSettings},
    computed: {
      userLoggedIn() {
        return userLoggedIn
      },
        user() {
            return user
        },
    }
})
export default class About extends Vue {
  getCreditEnabled(): boolean {
    return globalSettings.value.creditEnabled
  }

  login() {
    AuthService.login()
  }
}
