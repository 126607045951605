

import {Options, Vue} from "vue-class-component";
import {ElTable, ElTableColumn} from "element-plus";
import {MoreFilled} from "@element-plus/icons-vue";
import {MemberAccountCharge} from "@/model/MemberAccountCharge";

@Options({
  props: {
    charges: {
      type: Object as () => MemberAccountCharge[],
      required: true
    },
  },
  components: {MoreFilled, ElTable, ElTableColumn}
})

export default class ChargesContainer extends Vue {
  charges: MemberAccountCharge[] = []

  get chargesSorted(): MemberAccountCharge[] {
    return this.charges.sort((t1, t2) => t1.createdDate > t2.createdDate ? -1 : 1)
  }

  tableRowClassName(tableData: any) {
    if (tableData.row.state == "PENDING") {
      return 'pending-row'
    } else if (tableData.row.state == "FAILED" || tableData.row.state == "REFUNDED") {
      return 'failed-row'
    }
    return ''
  }
}
