

import RESTService from "@/services/RESTService";
import {Item, ItemCategory, ItemContainer} from "@/model/Item";
import {Options, Vue} from "vue-class-component";
import ShopItem from "@/components/ShopItem.vue";

@Options({
  components: {ShopItem}
})
export default class ItemsAdministration extends Vue {
  private itemContainers: ItemContainer[] = [];
  private emptyItemContainer = new ItemContainer(new Item())
  private selectedCategory: ItemCategory = ItemCategory.COLDDRINKS

  mounted() {
    this.reloadItems()
  }

  async reloadItems() {
    this.itemContainers = (await RESTService.getItems()).map(i => new ItemContainer(i));
    this.emptyItemContainer = new ItemContainer(new Item())
  }

}