


import {Item, ItemCategory, ItemContainer} from "@/model/Item";
import { ElMessage } from 'element-plus'
import {Options, Vue} from "vue-class-component";
import RESTService from "@/services/RESTService";

@Options({
  props: {
    itemContainer: {
      type: Object as () => ItemContainer,
    },
    editable: Boolean,
    creatable: Boolean,
    buyable: Boolean,
  }
})
export default class ShopItem extends Vue {
  itemContainer!: ItemContainer
  editable = false
  creatable = false
  buyable = false
  popoverVisible = false

  get item(): Item {
    return this.itemContainer.item
  }

  //Needed to avoid jumping between tabs
  private selectedCategory: ItemCategory = this.item.category

  async reloadItem() {
    this.$forceUpdate()
  }

  async deleteItem() {
    await RESTService.deleteItem(this.item.id)
    ElMessage({
      message: 'Artikel erfolgreich gelöscht',
      type: 'success',
    })
    this.$emit("itemChanged")
  }

  async saveItem() {
    this.item.category = this.selectedCategory
    this.popoverVisible = false
    if (this.itemContainer.item.id.length > 0)
      await RESTService.updateItem(this.item)
    else {
      await RESTService.createItem(this.item)
    }
    ElMessage({
      message: 'Artikel erfolgreich gespeichert',
      type: 'success',
    })
    this.$emit("itemChanged")
  }

  handleChange(currentValue: number) {
    this.$emit("quantityChanged")
  }

}
