import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ItemsAdministration from "@/views/ItemsAdministration.vue";
import History from '../views/History.vue'
import Order from '../views/Order.vue'
import Profile from '../views/Profile.vue'
import TransactionAdministration from "@/views/TransactionAdministration.vue";
import PaymentAdministration from "@/views/PaymentAdministration.vue";
import StatusPage from "@/views/StatusPage.vue";
import About from "@/views/About.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/order/category/:category',
    name: 'order',
    component: Order
  },
  {
    path: '/items',
    name: 'items-admin',
    component: ItemsAdministration
  },
  {
    path: '/transactions',
    name: 'transactions-admin',
    component: TransactionAdministration
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/admin-payment',
    name: 'admin-payment-settings',
    component: PaymentAdministration
  },
  {
    path: '/status/:success',
    name: 'status',
    component: StatusPage
  },
  {
    path: '/about',
    name: 'about',
    component: About
  }, {
    path: '/logout',
    name: 'logout',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
