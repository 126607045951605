
import RESTService from "@/services/RESTService";
import {globalSettings} from "@/store";
import AuthService from "@/services/AuthService";
import authService from "@/services/AuthService";

// This class provides an alternative to event/observer based lifecycle management. The purpose is to provide
// a maintainable overview about the program lifecycle
class LifecycleService {

    //Called by AuthService
    onUserLoggedIn() {
        RESTService.getGlobalSettings().then((oRes) => {
            globalSettings.value = oRes
        })
    }

    //Called by main component
    onApplicationLoaded() {
        if (authService.shouldForceLogin()) {
            authService.login()
        } else {
            AuthService.completeAuthorizationIfPossible().then()
        }
    }
}

export default new LifecycleService()