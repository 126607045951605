import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_menu, {
        router: true,
        mode: "horizontal"
      }, {
        default: _withCtx(() => [
          (_ctx.loggedIn)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 0,
                index: "/order/category/colddrinks"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Kaufen")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.loggedIn)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 1,
                index: "/history"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Meine Käufe")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.loggedIn)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 2,
                class: "menu__right",
                style: {"height":"100%"}
              }, {
                default: _withCtx(() => [
                  (!_ctx.loggedIn)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        type: "primary",
                        size: "large",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.authService.login()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Login")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.loggedIn)
            ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                key: 3,
                index: "user"
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.user.name), 1)
                ]),
                default: _withCtx(() => [
                  (_ctx.loggedIn)
                    ? (_openBlock(), _createBlock(_component_el_menu_item, {
                        key: 0,
                        index: "/profile"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Mein Profil")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.loggedIn && _ctx.roles.includes('shop-admin'))
                    ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                        key: 1,
                        index: "/administration"
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode("Administration")
                        ]),
                        default: _withCtx(() => [
                          (_ctx.loggedIn)
                            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                key: 0,
                                index: "/items"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Artikel")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.loggedIn)
                            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                key: 1,
                                index: "/transactions"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Transaktionen")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.loggedIn && _ctx.getCreditEnabled())
                            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                key: 2,
                                index: "/admin-payment"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Guthaben")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.loggedIn)
                    ? (_openBlock(), _createBlock(_component_el_menu_item, {
                        key: 2,
                        index: "logout",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.authService.logout())),
                        style: {"color":"#ff797d"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Logout")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_config_provider, { size: "large" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}