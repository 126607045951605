

import {Vue} from "vue-class-component";
import RESTService from '../services/RESTService'
import { UserNotificationSettings } from "@/model/UserNotificationSettings";
import {ElMessage} from "element-plus";


export default class NotificationSettings extends Vue {

  value = ''
  sendMails = false

  async mounted() {
    var settings = await RESTService.getNotificationSettings()
    this.value = settings.interval
    this.sendMails = settings.sendMails
  }

  options = [
    {
      value: "PURCHASE",
      label: "Nach jedem Kauf"
    },
    {
      value: "DAY",
      label: "Am Ende des Tages"
    },
    {
      value: "WEEK",
      label: "Am Ende jeder Woche"
    },
    {
      value: "MONTH",
      label: "Am Ende jedes Monats"
    }
  ]

  async saveUserNotificationSettings() {
    await RESTService.putNotificationSettings(new UserNotificationSettings(this.sendMails, this.value)).then(() => {
      ElMessage({
        message: 'Einstellungen erfolgreich gespeichert!',
        type: 'success',
      });
    }).catch(() => {
      ElMessage.error('Ein Fehler ist aufgetreten! Die Einstellungen wurden nicht gespeichert!')
    })
  }

}


