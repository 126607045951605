
import {Options, Vue} from "vue-class-component";
import {Transaction} from "@/model/Transaction";
import {ref} from "vue";
import config from '@/config/config.json'
import RESTService from "@/services/RESTService";
import {ElMessage, ElTable, ElTableColumn} from "element-plus";
import {MoreFilled} from "@element-plus/icons-vue";

@Options({
  props: {
    transactions: {
      type: Object as () => Transaction[],
      required: true
    },
    administration: {
      type: Boolean,
      required: false
    }
  },
  components: {MoreFilled, ElTable, ElTableColumn}
})
export default class TransactionContainer extends Vue {
  drawer = ref(false)
  transactions: Transaction[] = []
  activeTransaction?: Transaction
  refundDialog = false
  refundLoading = false

  get transactionsSorted(): Transaction[] {
    return this.transactions.sort((t1, t2) => t1.createdDate > t2.createdDate ? -1 : 1)
  }

  mounted() {
    console.log(this.transactions);
  }

  tableRowClassName(tableData: any) {
    if (tableData.row.state == "PENDING") {
      return 'pending-row'
    } else if (tableData.row.state == "FAILED" || tableData.row.state == "REFUNDED") {
      return 'failed-row'
    }
    return ''
  }

  getReceipt() {
    window.location.href = config.baseURL + "/receipts/" +this.activeTransaction?.id
  }

  async refund() {
    if (this.activeTransaction) {
      this.refundLoading = true
      console.log("Refunding " + this.activeTransaction.id)
      try {
        await RESTService.refundTransaction(this.activeTransaction.id)
        ElMessage({
          message: 'Rückerstattung erfolgreich!',
          type: 'success',
        });
        this.refundLoading = false
        this.refundDialog = false
        this.$emit('refunded')
      } catch (e: any) {
        ElMessage.error('Die Rückerstattung ist fehlgeschlagen!')
        this.refundLoading = false
        this.refundDialog = false
        console.log(e)
      }
    }
  }

}
