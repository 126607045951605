

import {Options, Vue} from "vue-class-component";
import RESTService from '../services/RESTService'
import {ElLoading, ElMessage} from "element-plus";
import ChargesContainer from "@/components/ChargesContainer.vue";

@Options({
  components: {ChargesContainer}
})

export default class PaymentSettings extends Vue {

  value = ''
  paymentMethod = 'credit'
  paymentOptions = [
    {
      value: 'credit',
      label: 'Guthaben',
    },
    {
      value: 'payment-collection',
      label: 'Zahlungseinzug',
    },
    {
      value: 'bill',
      label: 'Auf Rechnung',
    }
  ]
  payDrawer = false
  paymentProvider = 'PayPal'
  creditValue = 10
  resultDialogVisible = false
  paymentSuccessful = true
  credit = 0.0

  charges = []

  openLoadingScreen = () => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Fahre bitte bei '+this.paymentProvider+" fort!",
      background: 'rgba(0, 0, 0, 0.7)',
    })
    setTimeout(() => {
      loading.close()
      this.resultDialogVisible = true
    }, 3000)

  }

  get creditFee() {
    return this.paymentProvider == 'PayPal' ?
        Math.floor((this.creditValue*0.0299+0.39)*100)/100
        : this.paymentProvider == 'Stripe' ?
            Math.floor((this.creditValue*0.014+0.25)*100)/100 : 0
  }


  async mounted() {
    this.credit = await RESTService.getCreditFromUser("me")
  }

  confirmClick() {
    this.payDrawer = false
    this.openLoadingScreen()
  }
}


